@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&display=swap');

@layer base {
  html {
    font-family: poppins, sans-serif;
  }
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-tap-highlight-color: transparent
}

.title {}

.subtitle {}

.image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-wrapper {
  margin-bottom: 4rem;
}

/* .swiper-slide {
  width: 570px !important;
} */

.swiper-slide-active {
  border-radius: 12px;
}

.swiper-pagination-bullet-active {
  background: #EF7E46 !important;
}

.backroundBoxShadow {
  box-shadow: 0px 0px 0px 2rem #EF7E4633, 0 0 0 5rem #EF7E461A;
}

.sectionPadding {
  padding-inline: 16px !important;
}


/* responsive */


@media (min-width: 480px) {}

@media (min-width: 768px) {
  .sectionPadding {
    padding-inline: 32px !important;
  }
}

@media (min-width: 1024px) {

  .sectionPadding {
    padding-inline: 40px !important;
  }
}

@media (min-width: 1280px) {

  .sectionPadding {
    padding-inline: 64px !important;
  }
}

@media (min-width: 1440px) {

  .sectionPadding {
    padding-inline: 144px !important;
  }
}